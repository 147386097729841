import { useState } from 'react';

import { Accordion, AccordionItem } from '@chakra-ui/react';

import { SearchEmpty } from 'src/Search/Empty/SearchEmpty';
import { SearchEmptyLoading } from 'src/Search/Empty/SearchEmptyLoading';
import { SearchFilter } from 'src/Search/Filter/SearchFilter';
import { SearchFilterChannels } from 'src/Search/Filter/SearchFilterChannels';
import { SearchFilterExperts } from 'src/Search/Filter/SearchFilterExperts';
import { SearchFooter } from 'src/Search/SearchFooter';
import { Question } from 'src/shared/components/Question/Question';
import { useESQuestionList } from 'src/shared/hooks/useESQuestionList';

export function SearchBrowse({
  res,
  hasFilter,
}: {
  res: ReturnType<typeof useESQuestionList>;
  hasFilter: boolean;
}) {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { loading, result } = res;
  const isEmpty = !loading && !result?.questions?.length;
  const isNotEmpty = !loading && !isEmpty;

  const index = selectedId
    ? result?.questions?.findIndex((q) => q.id === selectedId) ?? -1
    : -1;

  const total = result?.total ?? 0;
  const questions = result?.questions ?? [];

  return (
    <>
      <SearchFilter total={total} isLoading={loading} />
      {loading && <SearchEmptyLoading />}
      {isEmpty && <SearchEmpty hasFilter={hasFilter} />}
      {isNotEmpty && (
        <>
          <Accordion
            allowToggle
            mb={4}
            mt={8}
            index={index}
            onChange={(index) => {
              if (typeof index === 'number') {
                setSelectedId(questions[index]?.id ?? '');
              }
            }}
          >
            {result?.questions?.map((question) => (
              <AccordionItem
                key={question.id}
                border="none"
                mt={1.5}
                data-id={question.id}
              >
                {({ isExpanded }) => (
                  <>
                    <Question question={question} isExpanded={isExpanded} />
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
          <SearchFooter total={total ?? 0} loading={loading} />
          <SearchFilterExperts experts={result?.aggs?.experts} />
          <SearchFilterChannels channels={result?.aggs?.channels} />
        </>
      )}
    </>
  );
}
