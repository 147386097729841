import { MdClose } from 'react-icons/md';

import { Button, Flex, Icon, Text } from '@chakra-ui/react';

import { useDirectoryChannels } from 'src/shared/hooks/useDirectory';
import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function SearchFilterChannels({
  channels,
}: {
  channels?: Record<string, number>;
}) {
  const [channelId, setChannelId] = useSingleSearchParam('channel');

  const channelList = useDirectoryChannels({
    requireQbInChannel: true,
  });

  const sortedChannels = Object.entries(channels ?? {}).sort((a, b) => {
    // Sort by name
    // First look for channelId in channelList
    // If not found, look for channelId in channelList
    // If not found, return 0
    // If found, return 1
    // If not found, return 0
    const aChannel = channelList.find((c) => c.id === a[0]);
    const bChannel = channelList.find((c) => c.id === b[0]);
    return (aChannel?.name ?? '').localeCompare(bChannel?.name ?? '');
  });

  const selectedChannel = channelList.find((c) => c.id === channelId);

  return (
    <>
      <Text fontSize="16px" fontWeight={700} color="#033426" mt="80px">
        Channels
      </Text>

      <Flex flexWrap="wrap" mt={4}>
        {selectedChannel && (
          <Button
            mr={8}
            mb={4}
            variant="link"
            size="sm"
            fontSize="13px"
            lineHeight="16px"
            fontWeight={700}
            borderRadius="20px"
            color="#033426"
            onClick={() => setChannelId()}
            rightIcon={<Icon as={MdClose} />}
          >
            <span># {selectedChannel.name}</span>
          </Button>
        )}
        {!channelId &&
          sortedChannels
            .filter(([channel]) => channel !== channelId)
            .map(([c, count]) => {
              const channel = channelList.find((chan) => c === chan.id);

              if (!channel) return null;

              return (
                <Button
                  key={channel.id}
                  mr={6}
                  mb={4}
                  variant="link"
                  size="sm"
                  fontSize="13px"
                  lineHeight="16px"
                  fontWeight={400}
                  borderRadius="20px"
                  color="#033426"
                  onClick={() => setChannelId(channel?.id ?? '')}
                >
                  <span>
                    # {channel.name} ({count})
                  </span>
                </Button>
              );
            })}
      </Flex>
    </>
  );
}
