import { useEffect, useRef, useState } from 'react';

import { SearchFilter } from '@core/types/types.common';
import { EndpointError } from '@core/types/types.endpoint';
import { QuestionListEndpoint } from '@core/types/types.endpoint.question';

import { DEFAULT_LIMIT } from 'src/Search/const/Search.const.DEFAULT_LIMIT';
import { useBuster } from 'src/shared/hooks/useBuster';
import { callApi } from 'src/shared/util/util.callApi';

export interface QuestionFilter {
  filter?: SearchFilter;
  sorter?: string;
  condition?: boolean;
  skip?: number;
  limit?: number;
}

export function useESQuestionList(searchQuery: QuestionFilter) {
  const buster = useBuster('bust');
  const [result, setResult] = useState<QuestionListEndpoint['response'] | null>(
    null,
  );
  const [error, setError] = useState<EndpointError | null>(null);
  const [loading, setLoading] = useState(true);

  const uniqueRef = useRef('');

  const {
    condition,
    skip,
    sorter,
    filter = {},
    limit = DEFAULT_LIMIT,
  } = searchQuery;

  useEffect(() => {
    const uniqueId = `${JSON.stringify(filter)}-${sorter}-${buster}-${limit}-${skip}`;

    if (condition === false || uniqueRef.current === uniqueId) return;

    uniqueRef.current = uniqueId;
    setLoading(() => true);

    callApi<QuestionListEndpoint>('/question/list', {
      skip,
      sorter,
      limit,
      filter,
    }).then(([err, res]) => {
      if (uniqueRef.current === uniqueId) {
        setResult(res);
        setError(err);
        setLoading(false);
      }
    });
  }, [loading, condition, skip, sorter, limit, buster, JSON.stringify(filter)]);

  return {
    res: 'browse' as const,
    loading,
    error,
    result,
  };
}
