import { PRO_FLAGS } from '@core/const/const.PRO_FLAGS';
import { DBTeam } from '@core/types/types.db';
import { Features } from '@core/types/types.features';
import { isPro } from '@core/util/util.isPro';

export function flag(team: DBTeam, f: Features) {
  if (team.feature_flags?.[f]) return true;

  if (isPro(team) && PRO_FLAGS.includes(f)) return true;

  return false;
}
