import { useState } from 'react';

import { Text } from '@chakra-ui/react';
import { Heading } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import { SettingsLanguageEndpoint } from '@core/types/types.endpoint.settings';

import { LANGUAGES } from 'src/Settings/const/Settings.const.LANGUAGES';
import { useDirectoryUpdater } from 'src/shared/hooks/shared.hook.useDirectoryUpdater';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function SettingsLanguage() {
  const team = useDirectoryTeam();
  const [language, setLanguage] = useState(team.primary_language ?? 'English');
  const updater = useDirectoryUpdater();
  const submitter = useSubmitter(async (language: string) => {
    const [err, res] = await callApi<SettingsLanguageEndpoint>(
      `/settings/language`,
      {
        language,
      },
    );
    if (!res || err) {
      setLanguage(team.primary_language ?? 'English');
    }
    if (res) {
      updater({ team: res });
    }
  });

  return (
    <Flex alignItems="center">
      <Box>
        <Heading size="xs">Documentation Language</Heading>

        <Text mt={1} fontWeight={500} color="gray.500" fontSize="xs">
          Automatically translate answers to this language.
        </Text>
      </Box>
      <Select
        ml="auto"
        size="xs"
        w="auto"
        maxW="200px"
        value={language}
        onChange={(e) => {
          setLanguage(e.target.value);
          submitter.submit(e.target.value);
        }}
      >
        {LANGUAGES.map((l) => (
          <option key={l} value={l}>
            {l}
          </option>
        ))}
      </Select>
    </Flex>
  );
}
