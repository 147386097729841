import { useState } from 'react';

import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { SettingsExpertsEndpoint } from '@core/types/types.endpoint.settings';

import { UserField } from 'src/shared/components/UserField/UserField';
import { useDirectoryUpdater } from 'src/shared/hooks/shared.hook.useDirectoryUpdater';
import { useDirectoryUsers } from 'src/shared/hooks/useDirectory';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function SettingsExperts() {
  const users = useDirectoryUsers();
  const updater = useDirectoryUpdater();

  const expertIds = users.filter((user) => !!user.GS1PK).map(({ id }) => id);

  const [selectedIds, setSelectedIds] = useState(expertIds);

  const isDifferent =
    expertIds.length !== selectedIds.length ||
    selectedIds.filter((id) => !expertIds.includes(id)).length > 0;

  const submitter = useSubmitter(async () => {
    const [, res] = await callApi<SettingsExpertsEndpoint>(
      '/settings/experts',
      {
        expertIds: selectedIds,
      },
    );
    if (res) {
      updater({
        users: users.map((user) => {
          const expert = res.find((r) => r.id === user.id);
          return expert ? expert : user;
        }),
      });
    }
  });

  return (
    <Box mt={8}>
      <Heading size="md" mt={10} pb={2}>
        Knowledge Managers
      </Heading>

      <Box maxW="540px">
        <UserField
          isDisabled={submitter.isSubmitting}
          placeholder="+Add"
          excludeDeleted
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
      </Box>

      <Text fontSize="xs" fontWeight={500} color="gray.500" mt={2}>
        Managers will get notified when new knowledge is detected.
      </Text>

      {isDifferent && (
        <Button
          mt={4}
          colorScheme="action"
          size="sm"
          isDisabled={!selectedIds.length}
          isLoading={submitter.isSubmitting}
          onClick={submitter.submit}
        >
          Save
        </Button>
      )}
    </Box>
  );
}
