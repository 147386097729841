import { Box, Divider, Heading } from '@chakra-ui/react';

import { SettingsFeatureFlag } from 'src/Settings/components/SettingsFeatureFlag';
import { SettingsLanguage } from 'src/Settings/components/SettingsLanguage';

export function SettingsFeatures() {
  return (
    <Box mt={12} maxW="600px">
      <Heading size="md">Features</Heading>
      <Divider mt={2} mb={3} />

      <SettingsFeatureFlag
        feature={'ai_answer'}
        title="AI Answer"
        description="Generate answer using AI"
      />
      <Divider mt={3} mb={3} />

      <SettingsFeatureFlag
        feature={'declarative_response'}
        title="Knowledge Capture"
        description="Automatic documentation when people share knowledge in Slack"
      />
      <Divider mt={3} mb={3} />

      <SettingsLanguage />
      <Divider mt={3} mb={3} />
    </Box>
  );
}
