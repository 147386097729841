import { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import ReactTextareaAutosize from 'react-textarea-autosize';

import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { DBExtractTestCase } from '@core/types/types.db';
import {
  TesterExtractRunEndpoint,
  TesterExtractSaveEndpoint,
} from '@core/types/types.endpoint.tester';
import { classifyTitle } from '@core/util/util.classifyTitle';

import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function TesterExtractItem({
  item,
  onSave,
  index,
  refetch,
}: {
  item: DBExtractTestCase;
  onSave: (item: DBExtractTestCase) => void;
  index: number;
  refetch: () => void;
}) {
  const [value, setValue] = useState(item.input_text);
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useSingleSearchParam(`${index}`);
  const [isExpandable, setIsExpandable] = useState(false);

  const saveSubmitter = useSubmitter(
    async (classify?: 'none' | 'knowhow' | 'question') => {
      const [, res] = await callApi<TesterExtractSaveEndpoint>(
        '/tester/extract/save',
        {
          ts: item.ts,
          input_text: value,
          classify: classify ?? item.classify,
        },
      );

      if (res) {
        onSave(res);
      }
    },
  );

  const run = useSubmitter(async (target: 'status_quo' | 'experiment') => {
    await callApi<TesterExtractRunEndpoint>('/tester/extract/run', {
      ts: [item.ts],
      target,
    });
    refetch();
  });

  const type = item.classify;
  useEffect(() => {
    // If height is greater than 120px, set isExpandable to true
    if (ref.current && ref.current.scrollHeight > 120) {
      setIsExpandable(true);
    }
  }, [value]);

  return (
    <Flex overflow="hidden" ref={ref} maxH={expanded ? undefined : '120px'}>
      <Flex w="40%" flex="none" maxW="720px" p={3}>
        <Box mt={1} mr={2} w="32px">
          <Menu>
            <MenuButton
              as={Button}
              isDisabled={saveSubmitter.isSubmitting}
              variant="ghost"
              size="sm"
              px={0}
            >
              {classifyTitle(type).split(' ')[0]}
            </MenuButton>
            <MenuList>
              <MenuItem
                value="none"
                onClick={() => saveSubmitter.submit('none')}
              >
                {classifyTitle('none')}
              </MenuItem>
              <MenuItem
                value="knowhow"
                onClick={() => saveSubmitter.submit('knowhow')}
              >
                {classifyTitle('knowhow')}
              </MenuItem>
              <MenuItem
                value="question"
                onClick={() => saveSubmitter.submit('question')}
              >
                {classifyTitle('question')}
              </MenuItem>
            </MenuList>
          </Menu>
          {isExpandable && (
            <IconButton
              icon={<Icon as={expanded ? IoIosArrowUp : IoIosArrowDown} />}
              aria-label={expanded ? 'Collapse' : 'Expand'}
              size="sm"
              variant="ghost"
              onClick={() => setExpanded(expanded ? undefined : '1')}
            />
          )}
        </Box>
        <Textarea
          as={ReactTextareaAutosize}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => {
            if (value !== item.input_text) {
              saveSubmitter.submit();
            }
          }}
          isDisabled={saveSubmitter.isSubmitting}
          maxRows={20}
          minRows={1}
          minHeight={'42px'}
          placeholder={`Message`}
        />
      </Flex>

      <Flex w="100%" justifyContent={'space-between'} alignContent={'center'}>
        <Box w="50%" p={3}>
          <Button
            variant="ghost"
            size="sm"
            isLoading={item.running_status_quo}
            onClick={() => run.submit('status_quo')}
          >
            {classifyTitle(item.result.status_quo_classify)}
          </Button>
          {item.result.status_quo_qna.map((qna, index) => (
            <Box key={index} mt={3}>
              <Heading size="xs">{qna.question}</Heading>
              <Text size="xs">{qna.answer}</Text>
            </Box>
          ))}
        </Box>
        <Box w="50%" p={3}>
          <Button
            variant="ghost"
            size="sm"
            isLoading={item.running_experiment}
            onClick={() => run.submit('experiment')}
          >
            {classifyTitle(item.result.experiment_classify)}
          </Button>
          {item.result.experiment_qna.map((qna, index) => (
            <Box key={index} mt={3}>
              <Heading size="xs">{qna.question}</Heading>
              <Text size="xs">{qna.answer}</Text>
            </Box>
          ))}
        </Box>
      </Flex>
    </Flex>
  );
}
