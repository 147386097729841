import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Box, Center, Text } from '@chakra-ui/react';

import { AppAccount } from 'src/App/Account/App.Account';
import { AddBot } from 'src/App/App.AddBot';
import { AppSettingsButton } from 'src/App/App.SettingsButton';
import { Topbar } from 'src/App/Topbar/App.Topbar';
import { Logo } from 'src/shared/components/Logo';
import 'src/shared/css/base.css';
import 'src/shared/css/codeeditor.css';
import 'src/shared/css/editor.css';
import 'src/shared/css/focus-within-hack.css';
import 'src/shared/css/hover-within.css';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export interface LayoutProps {
  children: ReactNode;
}

export function App({ children }: LayoutProps) {
  const team = useDirectoryTeam();
  // Redirect to /onboarding if not completed
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (pathname !== '/onboarding' && !team.onboarding_complete) {
    navigate('/onboarding');
  }

  if (!team.slack_bot_token) {
    return (
      <Center flexDir="column" h="100vh" gap={4}>
        <Logo />
        <Text mt={4}>Add the bot to your Slack to use Question Base</Text>
        <AddBot />
        <AppAccount />
      </Center>
    );
  }

  if (pathname === '/onboarding') {
    return (
      <>
        {children}
        <AppAccount />
      </>
    );
  }

  return (
    <>
      <Box maxW="container.md" m="0 auto" height="auto" px={2} pb={24}>
        <Topbar />
        {children}
      </Box>
      <AppSettingsButton />
      <AppAccount />
    </>
  );
}
