import { useState } from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { SlackImportChannelEndpoint } from '@core/types/types.endpoint.slack';
import { timeAgo } from '@core/util/util.timeAgo';

import { SettingsModalIntegrations } from 'src/Settings/components/SettingsModalIntegrations';
import { useDirectoryUpdater } from 'src/shared/hooks/shared.hook.useDirectoryUpdater';
import { useFlag } from 'src/shared/hooks/shared.hook.useFlag';
import { useDirectoryChannels } from 'src/shared/hooks/useDirectory';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function SettingsImportModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isPro = useFlag('integrations');
  const updater = useDirectoryUpdater();
  const channels = useDirectoryChannels({
    excludeDeleted: true,
    requireQbInChannel: true,
  });

  const [selectedChannelId, setSelectedChannelId] = useState<string>(
    channels[0]?.id ?? '',
  );
  const [selectedDays, setSelectedDays] = useState('14');

  const selectedChannel = channels.find(
    (channel) => channel.id === selectedChannelId,
  );

  const submitter = useSubmitter(async () => {
    const [, res] = await callApi<SlackImportChannelEndpoint>(
      '/slack/import/channel',
      {
        channelId: selectedChannelId,
        days: parseInt(selectedDays, 10),
      },
    );

    if (res) {
      updater(res);
      onClose();
    }
  });

  if (!selectedChannel) {
    return null;
  }

  return (
    <>
      <Button onClick={onOpen} size="sm" colorScheme="action">
        Import History
      </Button>
      {!isPro && (
        <SettingsModalIntegrations
          isOpen={isOpen}
          feature="import"
          onClose={onClose}
        />
      )}
      <Modal isOpen={isPro && isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import History from Slack</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight={600}>Channel</Text>
            <Select
              mt={1}
              value={selectedChannelId}
              onChange={(e) => setSelectedChannelId(e.target.value)}
            >
              {channels.map((channel) => (
                <option key={channel.id} value={channel.id}>
                  # {channel.name}
                </option>
              ))}
            </Select>

            {/** Select field to choose the history length (14 days, 30 days, 90 days, 180 days and 365 days) */}
            <Text fontWeight={600} mt={6}>
              Length
            </Text>
            <Select
              mt={1}
              value={selectedDays}
              onChange={(e) => {
                setSelectedDays(e.target.value);
              }}
            >
              <option value="14">14 days</option>
              <option value="30">30 days</option>
              <option value="90">90 days</option>
              <option value="180">180 days</option>
              <option value="365">365 days</option>
            </Select>
            <Text fontSize="sm" mt={6}>
              This will <b>extract Q&As</b> going{' '}
              <b>{selectedDays} days back</b> since the bot joined the channel (
              {timeAgo(selectedChannel.created_at)})
              <br />
              <br />
              They will be added as <b>Unverified</b> in Question Base and we
              recommend verifying them afterwards for the best results.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="action"
              onClick={submitter.submit}
              isLoading={submitter.isSubmitting}
              isDisabled={submitter.isSubmitting}
            >
              Import
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
