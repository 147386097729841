import { ReactNode } from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { IoMdCheckmark } from 'react-icons/io';

import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { timeAgo } from '@core/util/util.timeAgo';

import { Directory } from 'src/shared/components/Directory/Directory';
import { useDeleter } from 'src/shared/components/Question/hooks/Question.hooks.useDeleter';
import { QuestionContext } from 'src/shared/components/Question/QuestionContext';
import { useIsEditor } from 'src/shared/hooks/shared.hook.useIsEditor';
import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';

export function QuestionVerifiedBar({ onEdit }: { onEdit: () => void }) {
  const question = useRequiredContext(QuestionContext);
  const isEditor = useIsEditor();

  const { isDeleting, onConfirmDelete, confirmer } = useDeleter(question.id);

  let veriText: ReactNode = (
    <>
      Verified by <Directory userId={question.verified_by} />
      {` — ${timeAgo(question.verified_at ?? '')}`}
    </>
  );
  let veriBg = 'rgba(229, 252, 235, 1)';
  let veriWeight = 400;
  const isUnverified = question.verified_by === 'UNVERIFIED';
  if (isUnverified) {
    veriWeight = 700;
    const sourceUrl = question.source_url;
    veriText = (
      <>
        Unverified
        {sourceUrl ? (
          <>
            &nbsp;→&nbsp;
            <Button
              as="a"
              variant="link"
              target="_blank"
              href={sourceUrl}
              size="sm"
              colorScheme="action"
              fontWeight="bold"
            >
              Slack Conversation
            </Button>
          </>
        ) : (
          ''
        )}
      </>
    );
    veriBg = 'rgba(248, 251, 250, 0.90)';
  }

  return (
    <Flex
      color="#3A3A3A"
      background={veriBg}
      fontWeight="medium"
      alignItems="center"
      px={5}
      py={2}
      minHeight="64px"
      borderBottomEndRadius="8px"
      borderBottomStartRadius="8px"
    >
      {!isUnverified && (
        <Icon
          as={IoIosCheckmarkCircle}
          color="rgba(3, 52, 38, 1)"
          w={6}
          h={6}
        />
      )}
      <Text
        ml={2}
        color={isUnverified ? 'rgba(3, 52, 38, 0.50)' : undefined}
        fontWeight={veriWeight}
        fontSize="13px"
        lineHeight="16px"
      >
        {veriText}
      </Text>
      {isUnverified && isEditor && (
        <>
          <Button
            ml="auto"
            size="sm"
            isLoading={isDeleting}
            onClick={onConfirmDelete}
            fontWeight={600}
            fontSize="13px"
            lineHeight="16px"
            colorScheme="red"
            px={5}
          >
            Delete
          </Button>
          <Button
            ml={3}
            onClick={onEdit}
            size="sm"
            fontWeight={600}
            fontSize="13px"
            lineHeight="16px"
            leftIcon={<Icon as={IoMdCheckmark} />}
            px={4}
            color="#033426"
            background="#72F57F"
            _hover={{
              background: '#58D95E',
            }}
          >
            Verify
          </Button>

          {confirmer}
        </>
      )}
    </Flex>
  );
}
