import { SettingsChannels } from 'src/Settings/SettingsChannels';
import { SettingsExperts } from 'src/Settings/SettingsExperts';
import { SettingsFeatures } from 'src/Settings/SettingsFeatures';
import { SettingsIntegrations } from 'src/Settings/SettingsIntegrations';
import { SettingsNotion } from 'src/Settings/SettingsNotion';

export function SettingsPage() {
  return (
    <>
      <SettingsIntegrations />
      <SettingsExperts />
      <SettingsChannels />
      <SettingsFeatures />
      <SettingsNotion />
    </>
  );
}
