import { RefObject } from 'react';
import { IoClose } from 'react-icons/io5';

import {
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  Icon,
  IconButton,
  Text,
} from '@chakra-ui/react';

import { Answer } from 'src/shared/components/Answer';
import { QuestionActions } from 'src/shared/components/Question/Actions/QuestionActions';
import { QuestionContext } from 'src/shared/components/Question/QuestionContext';
import { QuestionVerifiedBar } from 'src/shared/components/Question/QuestionVerifiedBar';
import { QuestionSorter } from 'src/shared/components/Question/Sorter/QuestionSorter';
import { useDirectoryUser } from 'src/shared/hooks/useDirectory';
import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';

export function QuestionView({
  isExpanded,
  setEditMode,
  buttonRef,
  isCloseable,
}: {
  isExpanded?: boolean;
  setEditMode: (editMode: boolean) => void;
  buttonRef: RefObject<HTMLButtonElement>;
  isCloseable?: boolean;
}) {
  const question = useRequiredContext(QuestionContext);
  const user = useDirectoryUser(question.verified_by);

  return (
    <>
      <Flex alignItems="center" transition="0.25s" px={5}>
        <Avatar
          src={user?.image}
          name={user?.name}
          height="32px"
          width="32px"
        />

        <Flex width="100%" minH="64px" alignItems="center">
          <Text
            fontWeight={500}
            fontSize="15px"
            lineHeight="20px"
            color="#033426"
            letterSpacing="-0.01em"
            pr={2}
            ml={4}
            py={5}
          >
            {question.title}
          </Text>
        </Flex>
        <Center ml="auto" pr={1}>
          {isExpanded ? (
            <QuestionActions onEdit={() => setEditMode(true)} />
          ) : (
            <QuestionSorter />
          )}
        </Center>

        <AccordionButton
          as={IconButton}
          ref={buttonRef}
          variant="link"
          flex="none"
          width="32px"
          height="32px"
          size="sm"
          icon={isCloseable ? <Icon as={IoClose} /> : <AccordionIcon />}
        />
      </Flex>

      <AccordionPanel p={0} mt={0}>
        <Divider />
        <Box py={6} px={8}>
          <Answer
            question={question}
            editable={false}
            style={{ padding: 0, minHeight: 'auto' }}
          />
        </Box>
        <QuestionVerifiedBar onEdit={() => setEditMode(true)} />
      </AccordionPanel>
    </>
  );
}
