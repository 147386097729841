import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {
  Accordion,
  AccordionItem,
  Button,
  Center,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { QuestionViewEndpoint } from '@core/types/types.endpoint.question';

import { QuestionRelated } from 'src/Question/QuestionRelated';
import { SearchInput } from 'src/Search/Input/SearchInput';
import { Question } from 'src/shared/components/Question/Question';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function QuestionPage() {
  const { id = '' } = useParams<{ id: string }>();
  const [value, onChange] = useState('');
  const [editMode] = useSingleSearchParam('edit');
  const { data, loading, error } = useApi<QuestionViewEndpoint>(
    '/question/view',
    {
      id,
    },
  );
  const navigate = useNavigate();
  const onSearch = (q: string) => {
    if (q) {
      const url = `/questions?q=${q}`;
      return navigate(url);
    }
  };

  return (
    <>
      <SearchInput
        placeholder="Search for other questions…"
        onSearch={onSearch}
        value={value}
        onChange={onChange}
        searchVal=""
      />
      {!data && loading && (
        <Heading size="sm" mt={12}>
          Loading...
        </Heading>
      )}
      {error && (
        <>
          <Heading size="sm" mt={12}>
            Error loading question :(
          </Heading>
          <Flex>
            <Button
              mt={4}
              colorScheme="error"
              mr={3}
              onClick={() => {
                window.location.reload();
              }}
            >
              Try again
            </Button>
            <Button as={Link} to="/questions" mt={4} colorScheme="action">
              Go to Question Base
            </Button>
          </Flex>
        </>
      )}
      {data && (
        <>
          <Accordion
            allowToggle
            mb={4}
            mt={12}
            index={[0]}
            onChange={(index) => {
              console.log('change', index);
              navigate('/questions');
            }}
          >
            <AccordionItem>
              <Question
                question={data.question}
                isExpanded
                isCloseable
                initialEditMode={!!editMode}
              />
            </AccordionItem>
          </Accordion>
          <QuestionRelated question={data.question} />
          <Center mt={12}>
            <Button as={Link} to="/questions" colorScheme="action">
              Explore Question Base
            </Button>
          </Center>
        </>
      )}
    </>
  );
}
