import { Box, Button, Flex, Heading, Switch, Text } from '@chakra-ui/react';
import { SettingsFeatureEndpoint } from '@core/types/types.endpoint.settings';
import { Features } from '@core/types/types.features';

import { useDirectoryUpdater } from 'src/shared/hooks/shared.hook.useDirectoryUpdater';
import { useFlag } from 'src/shared/hooks/shared.hook.useFlag';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export interface SettingsFeatureFlag {
  title: string;
  description: string;
  learnMore?: string;
  feature: Features;
}

export function SettingsFeatureFlag({
  title,
  description,
  learnMore,
  feature,
}: SettingsFeatureFlag) {
  const updater = useDirectoryUpdater();

  const isChecked = useFlag(feature);
  const submitter = useSubmitter(async () => {
    const [, res] = await callApi<SettingsFeatureEndpoint>(
      `/settings/feature`,
      {
        feature,
        enabled: !isChecked,
      },
    );
    if (res) {
      updater({
        team: res,
      });
    }
  });
  return (
    <Flex alignItems="center">
      <Box>
        <Heading size="xs">{title}</Heading>

        <Text mt={1} fontWeight={500} color="gray.500" fontSize="xs">
          {description}
          {learnMore && (
            <Button
              as="a"
              ml={1}
              variant="link"
              colorScheme="blue"
              fontSize="xs"
              cursor="pointer"
              target="_blank"
              fontWeight={700}
              href={learnMore}
            >
              Learn more
            </Button>
          )}
        </Text>
      </Box>
      <Switch
        ml="auto"
        isDisabled={submitter.isSubmitting}
        isChecked={isChecked}
        onChange={() => {
          submitter.submit();
        }}
      />
    </Flex>
  );
}
