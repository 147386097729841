import { Features } from '@core/types/types.features';
import { flag } from '@core/util/util.flag';

import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export function useFlag(f: Features) {
  const team = useDirectoryTeam();

  return flag(team, f);
}
