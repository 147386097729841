import { memo, useRef, useState } from 'react';

import { QuestionContext } from './QuestionContext';
import { Box } from '@chakra-ui/react';
import { QuestionSearchItem } from '@core/types/types.common';
import { DBQuestion } from '@core/types/types.db';
import { QuestionViewCountEndpoint } from '@core/types/types.endpoint.question';

import { QuestionEdit } from 'src/shared/components/Question/QuestionEdit';
import { QuestionView } from 'src/shared/components/Question/QuestionView';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';

export interface QuestionProps {
  question: QuestionSearchItem | DBQuestion;
  isExpanded?: boolean;
  isCloseable?: boolean;
  initialEditMode?: boolean;
}

export const Question = memo(function Question({
  question,
  isExpanded,
  isCloseable,
  initialEditMode = false,
}: QuestionProps) {
  const [editMode, setEditMode] = useState<boolean>(initialEditMode);

  useApi<QuestionViewCountEndpoint>(
    '/question/viewCount',
    {
      id: question.id,
    },
    isExpanded,
  );

  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <QuestionContext.Provider value={question}>
      <Box
        onClick={() => {
          if (isExpanded || editMode) return;
          buttonRef.current?.click();
        }}
        cursor={isExpanded || editMode ? undefined : 'pointer'}
        py={0}
        p={isExpanded ? 0 : '1px'}
        _hover={{
          background: isExpanded ? undefined : '#F0F8F5',
          border: isExpanded ? undefined : '1px solid rgba(3, 52, 38, 0.5)',
        }}
        border={
          isExpanded
            ? '2px solid rgba(3, 52, 38, 0.9)'
            : '1px solid rgba(3, 52, 38, 0.2)'
        }
        borderRadius="8px"
        background="#ffffff"
      >
        {editMode ? (
          <QuestionEdit setEditMode={setEditMode} buttonRef={buttonRef} />
        ) : (
          <QuestionView
            isExpanded={isExpanded}
            setEditMode={setEditMode}
            buttonRef={buttonRef}
            isCloseable={isCloseable}
          />
        )}
      </Box>
    </QuestionContext.Provider>
  );
});
