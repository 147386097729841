import { useEffect } from 'react';
import { BsFillLightningChargeFill } from 'react-icons/bs';

import {
  Button,
  Heading,
  Icon,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DirectoryAnalyticsEndpoint } from '@core/types/types.endpoint.directory';
import {
  IntegrationAuthEndpoint,
  IntegrationUsageEndpoint,
} from '@core/types/types.endpoint.integration';
import {
  CarbonConnect,
  EmbeddingGenerators,
  IntegrationName,
} from 'carbon-connect';

import { SettingsModalIntegrations } from 'src/Settings/components/SettingsModalIntegrations';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useFlag } from 'src/shared/hooks/shared.hook.useFlag';
import { callApi } from 'src/shared/util/util.callApi';

export function SettingsIntegrations() {
  const hasIntegrations = useFlag('integrations');

  const { data, error, loading } = useApi<IntegrationUsageEndpoint>(
    '/integration/usage',
    {},
    hasIntegrations,
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isOpen) {
      callApi<DirectoryAnalyticsEndpoint>('/directory/analytics', {
        event: 'Viewed Integrations',
      });
    }
  }, [isOpen]);

  const tokenFetcher = async () => {
    const [err, res] = await callApi<IntegrationAuthEndpoint>(
      '/integration/auth',
      {},
    );

    if (err || !res) {
      throw new Error('Failed to fetch token');
    }

    return res;
  };

  let label = '';
  if (loading) {
    label = 'Loading...';
  } else if (!error && !data?.connections.length) {
    label = 'No integrations connected yet';
  }

  return (
    <>
      <Heading size="md" mt={6}>
        Knowledge Sources
      </Heading>

      {hasIntegrations && (
        <>
          <Text mt={2}>Connected accounts: {label}</Text>
          {!!data?.connections.length &&
            data?.connections.map(({ icon, service }, i) => (
              <Text mt={2} key={i} display="flex" alignItems="center">
                <Image src={icon} w={6} h={6} mr={2} />
                {service}
              </Text>
            ))}
        </>
      )}

      <Button
        leftIcon={<Icon as={BsFillLightningChargeFill} />}
        colorScheme="action"
        mt={3}
        size="sm"
        onClick={onOpen}
      >
        Manage Integrations
      </Button>
      <Text mt={2} fontWeight={500} color="gray.500" fontSize="xs">
        Include search results from your favorite tools
      </Text>
      {!hasIntegrations && (
        <SettingsModalIntegrations
          isOpen={isOpen}
          onClose={onClose}
          feature="integrations"
        />
      )}
      {hasIntegrations && (
        <CarbonConnect
          orgName="Question Base"
          brandIcon="/assets/logoimage.png"
          embeddingModel={EmbeddingGenerators.COHERE_MULTILINGUAL_V3}
          tokenFetcher={tokenFetcher}
          setOpen={(_isOpen) => {
            if (_isOpen) {
              onOpen();
            } else {
              onClose();
            }
          }}
          open={isOpen}
          environment="PRODUCTION"
          enabledIntegrations={[
            {
              id: IntegrationName.NOTION,
              syncFilesOnConnection: false,
              enableAutoSync: true,
              incrementalSync: true,
            },
            {
              id: IntegrationName.ZENDESK,
              syncFilesOnConnection: false,
              enableAutoSync: true,
              incrementalSync: true,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.FRESHDESK,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.INTERCOM,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.GURU,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.CONFLUENCE,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.GOOGLE_DRIVE,
              syncFilesOnConnection: false,
              enableAutoSync: true,
              incrementalSync: true,
            },
            {
              id: IntegrationName.DROPBOX,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.BOX,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.SALESFORCE,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.GITHUB,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
          ]}
          chunkSize={1500}
          overlapSize={20}
        ></CarbonConnect>
      )}
    </>
  );
}
