import { useState } from 'react';
import { LuExternalLink, LuLogOut } from 'react-icons/lu';

import {
  Avatar,
  Button,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  Portal,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { BillingLinkEndpoint } from '@core/types/types.endpoint.billing';

import { AppAccountButton } from 'src/App/Account/App.AccountButton';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useDirectoryMe } from 'src/shared/hooks/useDirectory';
import { redirectToLogin } from 'src/shared/util/util.redirectToLogin';

export function AppAccount() {
  const me = useDirectoryMe();

  const [loadBilling, setLoadBilling] = useState(false);

  const { data, loading } = useApi<BillingLinkEndpoint>(
    '/billing/link',
    {},
    loadBilling,
  );

  const disclosure = useDisclosure();
  const { isOpen, onClose } = disclosure;

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        placement="top-end"
        isOpen={isOpen}
        onClose={onClose}
        matchWidth
      >
        <AppAccountButton disclosure={disclosure} />
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader display="flex" alignItems="center">
              <Avatar
                pointerEvents="none"
                transition=".25s"
                src={me.image}
                name={me.name}
                height="36px"
                width="36px"
              />
              <Heading size="sm" ml={2}>
                {me.name}
              </Heading>
              <Tooltip label="Log out">
                <IconButton
                  variant="ghost"
                  ml="auto"
                  size="sm"
                  onClick={() => {
                    redirectToLogin('goToHome');
                  }}
                  icon={<Icon w={5} h={5} as={LuLogOut} />}
                  aria-label="Logout"
                />
              </Tooltip>
            </PopoverHeader>
            <PopoverBody pb={10}>
              <Heading fontSize="13px" mt={6} color="action.400">
                Billing
              </Heading>
              {!data && (
                <Button
                  variant="link"
                  colorScheme="action"
                  onClick={() => {
                    setLoadBilling(true);
                  }}
                  isDisabled={loading}
                >
                  {loading ? 'Generating link...' : 'Open billing'}
                </Button>
              )}
              {data && (
                <Button
                  as="a"
                  target="_blank"
                  href={data.url}
                  variant="link"
                  colorScheme="action"
                  rightIcon={<Icon w={5} h={5} as={LuExternalLink} />}
                >
                  Stripe Billing Portal
                </Button>
              )}
              <Heading fontSize="13px" mt={6} color="action.400">
                Contact support
              </Heading>
              <Button
                as="a"
                target="_blank"
                href="mailto:help@questionbase.com"
                variant="link"
                colorScheme="action"
              >
                help@questionbase.com
              </Button>
              <Heading fontSize="13px" mt={6} color="action.400">
                Free personal onboarding
              </Heading>
              <Button
                as="a"
                target="_blank"
                href="https://cal.com/team/questionbase/call"
                variant="link"
                colorScheme="action"
              >
                Book a call
              </Button>
              <Heading fontSize="13px" mt={6} color="action.400">
                Feedback
              </Heading>
              <Button
                as="a"
                target="_blank"
                href="https://forms.gle/3o6z1w74Ke4NHaMN9"
                variant="link"
                colorScheme="action"
              >
                Send feedback
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  );
}
