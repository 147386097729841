import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_LIMIT } from 'src/Search/const/Search.const.DEFAULT_LIMIT';
import { useBroadcaster } from 'src/shared/hooks/shared.hook.useBroadcaster';
import { useESQuestionList } from 'src/shared/hooks/useESQuestionList';
import { useESQuestionSearch } from 'src/shared/hooks/useESQuestionSearch';

export function useQuestionFilter() {
  const [search, setSearch] = useSearchParams();
  const prevSearch = useRef(search);
  const q = search.get('q') ?? '';

  const sorter = search.get('sorter') ?? '';
  const expert = search.get('expert') ?? undefined;
  const channel = search.get('channel') ?? undefined;

  const limit =
    parseInt(search.get('l')?.replace(/[^0-9]*/g, '') || '0', 10) ||
    DEFAULT_LIMIT;
  const page =
    parseInt(search.get('p')?.replace(/[^0-9]*/g, '') || '0', 10) || 1;

  const hasNoFilter = page === 1 && !q && !expert && !channel;

  // If these search params have changed, reset the page
  useEffect(() => {
    const busters = ['sorter', 'l', 'expert', 'channel'];
    const changed = busters.some((key) => {
      return prevSearch.current.get(key) !== search.get(key);
    });
    if (changed && search.get('p')) {
      search.delete('p');
      setSearch(search);
    }
    prevSearch.current = search;
  });

  const browseRes = useESQuestionList({
    sorter,
    limit,
    skip: (page - 1) * limit,
    filter: {
      verified_by: expert,
      slack_channel_id: channel,
    },
    condition: !q,
  });

  const searchRes = useESQuestionSearch({
    query: q,
  });

  // Listen for question updates
  useBroadcaster((e) => {
    let rerender = false;
    if (e.event === 'question:delete') {
      if (browseRes.result) {
        browseRes.result.questions = browseRes.result.questions.filter(
          (q) => e.questionId !== q.id,
        );
        rerender = true;
      }
      if (searchRes.result) {
        searchRes.result.answers = searchRes.result.answers.filter(
          (q) => q.service !== 'questionbase' || e.questionId !== q.id,
        );
        rerender = true;
      }
    }
    if (e.event === 'question:save') {
      if (browseRes.result) {
        browseRes.result.questions = browseRes.result.questions.map((q) =>
          q.id === e.question.id ? e.question : q,
        );
        rerender = true;
      }

      if (searchRes.result) {
        searchRes.result.answers = searchRes.result.answers.map((q) => {
          if (q.service === 'questionbase' && q.id === e.question.id) {
            q.raw_answer = e.question;
          }
          return q;
        });
        rerender = true;
      }
    }

    if (rerender) {
      return 'force-rerender';
    }
  });

  return { result: q ? searchRes : browseRes, hasFilter: !hasNoFilter };
}
