import { createContext, ReactNode, useMemo } from 'react';

import { Directory } from '@core/types/types.common';
import { DBUser } from '@core/types/types.db';
import { filterChannels } from '@core/util/util.filterChannels';

import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';

export const DirectoryContext = createContext<Directory | undefined>(undefined);

export interface DirectoryProviderProps {
  children: ReactNode;
  directory: Directory;
}

export const makeUnknownUser = (teamId: string, userId: string): DBUser => ({
  id: userId,
  name: 'Unknown User',
  created_at: new Date().toISOString(),
  first_name: 'Unknown',
  email: '',
  TYPE: 'user',
  slack_team_id: teamId,
  PK: `TEAM#${teamId}|USER`,
  SK: `USER#${userId}`,
  image: '',
  expert_channels: [],
  is_full_user: false,
});

export const makeUnverifiedUser = (teamId: string): DBUser => ({
  id: 'UNVERIFIED',
  name: 'Unverified',
  created_at: new Date().toISOString(),
  first_name: 'Unverified',
  email: '',
  TYPE: 'user',
  slack_team_id: teamId,
  PK: `TEAM#${teamId}|USER`,
  SK: `USER#UNVERIFIED`,
  image: '/assets/unverified.png',
  expert_channels: [],
  is_full_user: false,
});

export function useDirectoryMe() {
  return useRequiredContext(DirectoryContext).me;
}

export function useDirectoryTeam() {
  return useRequiredContext(DirectoryContext).team;
}

export function useDirectoryChannel(channelId?: string) {
  const directory = useRequiredContext(DirectoryContext);
  return directory.channels.find((channel) => channel.id === channelId);
}

export function useDirectoryChannels(
  options: Parameters<typeof filterChannels>[1],
) {
  const directory = useRequiredContext(DirectoryContext);

  return useMemo(
    () => filterChannels(directory.channels, options),
    [directory.channels, options],
  );
}

export function useDirectoryUser(userId?: string) {
  const directory = useRequiredContext(DirectoryContext);

  return useMemo(() => {
    if (!userId) return null;
    if (userId === 'UNVERIFIED') return makeUnverifiedUser(directory.team.id);

    const user = directory.users.find((user) => userId === user.id);
    return user;
  }, [userId, directory]);
}

export function useDirectoryUsers(userIds?: string[]) {
  const directory = useRequiredContext(DirectoryContext);

  return useMemo(() => {
    if (!directory.team) return [];

    const users = directory.users;

    if (!userIds) return users;

    return userIds.map((userId) => {
      if (userId === 'UNVERIFIED') return makeUnverifiedUser(directory.team.id);
      const foundUser = users.find((user) => userId === user.id);

      if (!foundUser) {
        return makeUnknownUser(directory.team.id, userId);
      }
      return foundUser;
    });
  }, [userIds?.join(',') ?? '', directory]);
}
